import React from 'react';
// import './Marker.css';

const Marker = (props) => {

  const pet = props.pet;
  const displayInfo = (e) => {
    e.stopPropagation();
    const markerInfo = document.querySelectorAll('.marker-info');
    const thisMarkerInfo = e.target.childNodes[0];
    markerInfo.forEach(function(el, index) {
      el.classList.remove('is-active');
    });
    thisMarkerInfo.classList.toggle('is-active');
  }

  const closeInfo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.parentNode.classList.remove('is-active');
  }

  return (
    <div className="marker" title={props.name} onClick={(e)=>displayInfo(e)}>
      <span className="marker-info">
        <p>{pet.petName}</p>
        <p>{pet.petColor}</p>
        <button onClick={(e)=>closeInfo(e)}>Close </button>
      </span>
    </div>
  );
};

export default Marker;
