import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';
import axios from 'axios';

const LicensesMap = (props) => {
    const [center] = useState({lat: 37.5246403, lng: -77.5633018 });
    const [zoom] = useState(11);
    const [pets, setPets] = useState();

    useEffect(() => {
      axios.get('https://roamingtails-api2.herokuapp.com/pets', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}  `,
        },
        params: {
          petCounty: "Richmond City"
        },
      })
      .then(function (response) {
        setPets(response.data);

        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    }, [])

    const renderMarkers = pets?pets.map((pet, index) => {
      return (
        <Marker
          key={index}
          pet={pet}
          lat={pet.petLat}
          lng={pet.petLng}
          name="My Marker"
        />
      )
    }):"";

    return (
        <div style={{ height: '100vh', width: '100%' }}>
        {pets?
          <GoogleMapReact
            // bootstrapURLKeys={{ key: 'AIzaSyAUmua4dWmdxe1Yb0PlxyhQ9-zhSJe8l9w' }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            {renderMarkers}
          </GoogleMapReact>:
          <section>
            <p>You must have admin rights to view this information</p>
          </section>}
      </div>
    );
}

export default LicensesMap;
