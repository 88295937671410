import React from 'react';

import TermsCat from 'assets/terms-cat.png';
import TermsDog from 'assets/terms-dog.png';

const Step6 = (props) => {

  return (
    <div class={props.step===6?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab6-content">
			<div class="column is-10 ">
				<div class="columns is-centered">
					<div class="column is-10">
						<div class="card terms-card">
							<div class="card-header has-text-centered">
                <img src={TermsDog} alt="Dog illustration" />
								<p class="has-text-weight-bold is-size-4">Thank you!</p>
								<img src={TermsCat} alt="Cat illustration" />
							</div>
						</div>
						<section class="section">
						<div class="field">
							<p class="has-text-weight-bold">Thank you for purchasing an Richmond City Pet License.  </p>
						</div>
						<div class="field">
							<p>Your tag will be mailed to you w/in 10 days of verifying your pet’s rabies certification. </p>
						</div>
						<div class="field">
							<p>If you did not upload a certification please mail to the following address:
								<br/> <b>2130 Van Buren St., Hollywood, FL  33020</b></p>
						</div>
						{/* <a class="button is-primary" href="/richmond-sort">Back to our website</a> */}
						</section>
					</div>
				</div>
			</div>
		</div>
  );
}

export default Step6;
