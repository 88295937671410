import React from 'react';
import { AuthConsumer } from '../utils/AuthContext';
import { Link } from 'react-router-dom';

const Nav = (props) => {

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, user }) => (
        <section className="section">
          <aside className="menu">
            {/* {user&&user.username&&user.role?
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img src="https://bulma.io/images/placeholders/96x96.png" alt="Profile pic" className="is-rounded" />
                </figure>
              </div>
              <div className="media-content">
                <p className="has-text-weight-bold is-size-6">{user.username}</p>
                <p className="is-size-7">{user.role.name}</p>
              </div>
            </div>:""} */}
            <p className="menu-label">
              Pet owners
            </p>
            <ul className="menu-list">
              <li>
                <Link to="/license">Licensing</Link>
              </li>
            </ul>
            <p className="menu-label">
              County admin
            </p>
            <ul className="menu-list">
              <li><Link to="/dashboard/rabies">Rabies</Link></li>
              <li><Link to="/dashboard/licenses">Licenses</Link></li>
              <li><Link to="/map">Map</Link></li>
            </ul>

            <p className="menu-label">
              Veterinarians
            </p>
            <ul className="menu-list">
              {/* <li><Link to="/vets/offices">Vet offices</Link></li> */}
              {/* <li><Link to="/vets/owners">Owners</Link></li> */}
              <li><Link to="/vets/send">Send certificate</Link></li>
            </ul>
            <hr></hr>
            {isAuth?
              <button className="button is-text" onClick={(e) => logout(e)}>Logout</button>
            :""}
          </aside>
        </section>
      )}
    </AuthConsumer>
  );
}

export default Nav;
