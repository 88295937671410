import React, { useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';

import { CSVLink } from "react-csv";

import termsCat from 'assets/terms-cat.png';
import termsDog from 'assets/terms-dog.png';

const Licenses = (props) => {

  const [licenses, setLicenses] = useState();
  console.log(props);

  useEffect(() => {
    if (props.county!== null && props.county!== undefined) {
        axios.get('https://roamingtails-api2.herokuapp.com/licenses', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}  `,
          },
        })
        .then(function (response) {
          console.log(response);
          setLicenses(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
      }
  }, []);

  let csvRows = []
  if (licenses) {
    licenses.forEach((item, index) => {
        csvRows.push(item);
      })
  }
  // console.log(csvRows);

  let csvData;
  if (licenses) {
    csvData = csvRows;
    // console.log(csvData)
  }

  return (
    <div>
      {licenses?
        <section className="section">
          <div className="card terms-card" style={{marginBottom: "24px"}}>
            <div className="card-header has-text-centered">
              <img src={termsDog} alt="" />
              <div style={{margin: "auto"}}>
                <p className="has-text-weight-bold is-size-4">Pet licenses directory</p>
                <p className="is-size-7">List of all licensed pets from Richmond City</p>
              </div>
              <img src={termsCat} alt="" />
            </div>
          </div>
          {licenses?
          <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth" id="myTable">
              <thead>
                <tr>
                  <th nowrap="true" className="has-text-white">No.</th>
                  <th nowrap="true" className="has-text-white">First name</th>
                  <th nowrap="true" className="has-text-white">Last name</th>
                  <th nowrap="true" className="has-text-white">Email</th>
                  <th nowrap="true" className="has-text-white">Street</th>
                  <th nowrap="true" className="has-text-white">City</th>
                  <th nowrap="true" className="has-text-white">State</th>
                  <th nowrap="true" className="has-text-white">County/City</th>
                  <th nowrap="true" className="has-text-white">ZIP</th>
                  <th nowrap="true" className="has-text-white">Pet type</th>
                  <th nowrap="true" className="has-text-white">Pet name</th>
                  <th nowrap="true" className="has-text-white">Pet date of birth</th>
                  <th nowrap="true" className="has-text-white">Pet weight</th>
                  <th nowrap="true" className="has-text-white">Pet breed</th>
                  <th nowrap="true" className="has-text-white">Pet color</th>
                  <th nowrap="true" className="has-text-white">Pet hair</th>
                  <th nowrap="true" className="has-text-white">Pet gender</th>
                  <th nowrap="true" className="has-text-white">Pet status</th>
                  <th nowrap="true" className="has-text-white">Cert date</th>
                  <th nowrap="true" className="has-text-white">Cert</th>
                  <th nowrap="true" className="has-text-white">Cert duration</th>
                  <th nowrap="true" className="has-text-white">Auto renew</th>
                  <th nowrap="true" className="has-text-white">Valid cert</th>
                  <th nowrap="true" className="has-text-white">Tag number</th>
                </tr>
              </thead>
              <tbody>
                {licenses.map((license, index) => (
                  <tr key={index}>
                      <td nowrap="true">{license.index}</td>
                      <td nowrap="true">{license.fname}</td>
                      <td nowrap="true">{license.lname}</td>
                      <td nowrap="true">{license.email}</td>
                      <td nowrap="true">{license.street}</td>
                      <td nowrap="true">{license.city}</td>
                      <td nowrap="true">{license.state}</td>
                      <td nowrap="true">{license.county}</td>
                      <td nowrap="true">{license.zip}</td>
                      <td nowrap="true">{license.petType}</td>
                      <td nowrap="true">{license.petName}</td>
                      <td nowrap="true">{moment(license.petDob).format('ll')}</td>
                      <td nowrap="true">{license.petWeight}</td>
                      <td nowrap="true">{license.petBreed}</td>
                      <td nowrap="true">{license.petColor}</td>
                      <td nowrap="true">{license.petHair}</td>
                      <td nowrap="true">{license.petGender}</td>
                      <td nowrap="true">{license.status}</td>
                      <td nowrap="true">{moment(license.certDate).format('ll')}</td>
                      <td nowrap="true"><a href={license.cert} target="_blank" rel="noopener noreferrer">Certificate file</a></td>
                      <td nowrap="true">{license.certDuration}</td>
                      <td nowrap="true">{license.autoRenew}</td>
                      <td nowrap="true">{license.certVerified}</td>
                      <td nowrap="true">{license.tagNo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          :<p>No licenses.</p>}
          {licenses?
            <div className="field">
              {/* <p>Export table as a .csv file</p> */}
              <CSVLink className="button is-primary" data={csvData}>Export .csv</CSVLink>
            </div>:
          ""}
        </section>:
        <section>
          <p>You must have admin rights to view this information</p>
        </section>}

    </div>
  );
}

export default Licenses;
