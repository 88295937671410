import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import './App.scss';
import { AuthProvider, AuthConsumer } from '../utils/AuthContext';
import RichmondLogo from 'assets/richmond.jpg';

import Nav from './nav';

import License from '../license';

// County dashboard
import Dashboard from '../dashboard';
import Rabies from '../dashboard/rabies';
import Licenses from '../dashboard/licenses';

import Map from '../map';

// Vet portal
import Vets from '../vets';
import VetOffices from '../vets/vetOffices';
import SendCert from '../vets/sendCert';

import Login from '../account/login';

const App = () => {

  // const [sidebar, setSidebar] = useState(true);

  const toggleSidebar = () => {
    // console.log(document.querySelector('.is-sidebar'));
    document.querySelector('.is-sidebar').classList.toggle('is-hidden');
    document.querySelector('.is-content').classList.toggle('is-12-desktop');
  }

  const toggleMenu = () => {
    document.querySelector(".navbar-burger").classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }

  return (
    <Router>
      <AuthProvider>
      <div>
        <AuthConsumer>
          {({ isAuth, login, logout, user, county }) => (
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand is-hidden-touch" style={{alignItems: "center", marginLeft: "1.5rem"}} onClick={() => toggleSidebar()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            </svg>
          </div>
          <div className="navbar-brand">
            <a className="navbar-item" href="http://www.richmondgov.com/AnimalControl/" target="_blank" rel="noopener noreferrer">
              <img src={RichmondLogo}  height="40" alt="Richmond City"/>
            </a>

            <p role="button" className="navbar-burger burger" onClick={()=>toggleMenu()}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </p>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start is-hidden-desktop">
              <p className="menu-label">
                Pet owners
              </p>
              <ul className="menu-list">
                <li>
                  <Link to="/license">Licensing</Link>
                </li>
              </ul>
              <p className="menu-label">
                County admin
              </p>
              <ul className="menu-list">
                <li><Link to="/dashboard/rabies">Rabies</Link></li>
                <li><Link to="/dashboard/licenses">Licenses</Link></li>
                <li><Link to="/map">Map</Link></li>
              </ul>

              <p className="menu-label">
                Veterinarians
              </p>
              <ul className="menu-list">
                <li><Link to="/vets/send">Send certificate</Link></li>
              </ul>
              {isAuth?
                <button className="button is-text" onClick={(e) => logout(e)}>Logout</button>
              :""}
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                {user&&user.username&&user.role?
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-24x24">
                      <img src="https://bulma.io/images/placeholders/96x96.png" alt="Profile pic" className="is-rounded" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="is-size-6">
                      <span className="has-text-weight-bold ">{user.username}</span>, {user.role.name}</p>
                  </div>
                </div>:""}
              </div>
            </div>
          </div>
        </nav>
        )}
        </AuthConsumer>
        <div className="columns">
          <div className="column is-3 is-sidebar is-hidden-touch">
            <Nav toggleSidebar={() => toggleSidebar()}/>
          </div>
          <AuthConsumer>
            {({ isAuth, login, logout, user, county }) => (

              <div className="column is-content is-9-desktop is-12-touch" style={{paddingLeft: "0px"}}>
              {/* <ProtectedRoute path="/" component={Account} /> */}
              {isAuth?
              <div>
                <div className="container">
                  <Route path="/license" component={License}/>

                  <Route exact path="/dashboard" component={Dashboard}/>
                  <Route path="/dashboard/rabies" component={() => <Rabies county={county} />}/>
                  <Route path="/dashboard/licenses" component={() => <Licenses county={county} />}/>
                  <Route exact path="/vets" component={Vets}/>
                  <Route path="/vets/offices" component={VetOffices}/>
                  <Route path="/vets/send" component={() => <SendCert user={user}/>}/>
                </div>


                <Route path="/map" component={() => <Map county={county} />}/>



              </div>:<Login />}

              {/* <Route path="/account" component={() => <Account user={user}/>}/> */}
              {/* <Route path="/account/login" component={Login} />
              <Route path="/account/register" component={Register} /> */}
              {/* <Route path="/profile" render={() => <Profile user={user}/>} /> */}
              </div>
            )}
          </AuthConsumer>
        </div>

      </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
