import React, {useState, useEffect} from 'react';
import axios from 'axios';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);

  const [user, setUser] = useState(localStorage.getItem('user'));

  const [county, setCounty] = useState();

  useEffect(() => {
    let loggedUser;
    loggedUser = JSON.parse(localStorage.getItem('user'));
    const localToken = localStorage.getItem('token');
    const localUser = localStorage.getItem('user');
    if (localToken && localUser) {
      setIsAuth(true);
    }
    if (loggedUser) {
      // console.log(loggedUser);
      axios.get('https://roamingtails-api2.herokuapp.com/users?id='+loggedUser.id)
        .then(function (res) {
          // console.log(res.data[0])
          setUser(res.data[0])
          setCounty(res.data[0].userCounty)
        })
        .catch(function (error) {
          console.log(error);
      });
    }
  }, [])

  const login = (e, history) => {
    e.preventDefault();
    axios.post('https://roamingtails-api2.herokuapp.com/auth/local', {
      identifier: document.getElementById('login-email').value,
      password: document.getElementById('login-password').value,
    })
    .then(function(res) {
      // console.log('User profile', res.data.user);
      // console.log('User token', res.data.jwt);
      localStorage.setItem('token', res.data.jwt);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      setUser(res.data.user);
      setIsAuth(true);
      setCounty(res.data.user.userCounty)
      // history.push('/');

    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const logout = (e, history) => {
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setIsAuth(false)
    setUser(null);
    setCounty(null);
    // history.push('/');
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        login: login,
        logout: logout,
        user: user,
        county: county
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
