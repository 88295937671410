import React, { useState } from 'react';
import axios from 'axios';
import './license-form.sass';

// Form steps
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';

import Puppies from 'assets/puppies.jpg';

const License = () => {

  const [step, setStep] = useState(1);

  const submitForm = () => {
    let fname = document.getElementById('fname');
    let lname = document.getElementById('lname');
    let email = document.getElementById('email');
    let street = document.getElementById('street');
    let usState = document.getElementById('state');
    let city = document.getElementById('city');
    let zip = document.getElementById('zip');
    let county = document.getElementById('county');

    let cat = document.getElementById('cat');
    let dog = document.getElementById('dog');
    let male = document.getElementById('male');
    let female = document.getElementById('female');
    let spayed = document.getElementById('spayed');
    let unaltered = document.getElementById('unaltered');
    let price = document.getElementById('total-price');

    let certDate = document.getElementById('certDate');
    let certTime = document.getElementById('certTime');
    let autoRenew = document.getElementById('auto-renew');

    let petName = document.getElementById('petName');
    let petDob = document.getElementById('petDob');
    let petWeight = document.getElementById('petWeight');
    let petColor = document.getElementById('petColor');
    let petBreed = document.getElementById('petBreed');
    let petHair = document.getElementById('petHair');

    var tagNo = "L" + Math.floor(100000 + Math.random() * 900000);
    let licenseData = {
      fname: fname.value,
      lname: lname.value,
      email: email.value,
      street: street.value,
      city: city.value,
      state: usState.value,
      county: county.value,
      zip: zip.value,
      certDate: certDate.value,
      certTime: certTime.value,
      // cert: imgUrl,
      autoRenew: autoRenew.checked,
      certVerified: false,
      tagNo: tagNo,
      tagActivated: false,
      petType: cat.checked?"Cat":"Dog",
      petStatus: spayed.checked?"Spayed/Neutered":"Unaltered",
      petGender: male.checked?"Male":"Female",
      petName: petName.value,
      petDob: petDob.value,
      petWeight: petWeight.value,
      petBreed: petBreed.value,
      petColor: petColor.value,
      petHair: petHair.value
    };

    console.log(licenseData)

    axios.post('https://roamingtails-api2.herokuapp.com/licenses', licenseData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}  `,
      },
    })
    .then(function(response) {
      console.log(response)
      if (step < 6) {
        setStep(step+1);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const nextStep = (e) => {
    e.preventDefault();
    if (step < 6) {
      setStep(step+1);
    }
    console.log(step);
  }
  const prevStep = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step-1);
    }
    console.log(step);
  }

  return (
    <div>
      <div className="broward-header" style={{backgroundImage: "url("+Puppies+")"}}>
  			<div className="container">
  				<div className="section has-text-centered">
  					<div className="columns is-centered">
  						<a href="/broward-sort" className="broward-logo">
  							{/* <img src="<?php echo $path?>/assets/img/arl.png" alt="" /> */}
  						</a>
  					</div>
  					<div className="columns is-centered">
  						<div className="column is-7">
  							<div className="broward-tagline">
  								<p className="has-text-white is-size-5 has-text-weight-bold">Thank you for protecting the safety of your pet by purchasing a Richmond City Pet License.</p>
  							</div>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
      <section className="section">
        <div className="columns is-centered">
					<div className="column is-10">
						<div className="columns is-mobile progress-tabs is-aligned-bottom is-multiline">
							<div className={step+1>1?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="1">
								<p className="is-uppercase is-size-7">Order details</p>
								<span></span>
							</div>
							<div className={step+1>2?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="2">
								<p className="is-uppercase is-size-7">Pet details</p>
								<span></span>
							</div>
							<div className={step+1>3?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="3">
								<p className="is-uppercase is-size-7">Rabies details</p>
								<span></span>
							</div>
							<div className={step+1>4?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="4">
								<p className="is-uppercase is-size-7">Terms & Conditions</p>
								<span></span>
							</div>
							<div className={step+1>5?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="5">
								<p className="is-uppercase is-size-7">Checkout</p>
								<span></span>
							</div>
						</div>
					</div>
				</div>

      <Step1 step={step}/>

      <Step2 step={step}/>

      <Step3 step={step}/>

      <Step4 step={step}/>

      <Step5 step={step}/>

      <Step6 step={step}/>


      <div className="columns is-centered" >
        <div className="column is-10">
          <div className="columns">
            <div className="column is-6">
              <div className="buttons">
                {step>1?<button onClick={(e)=>prevStep(e)} className="button is-white">Previous</button>:""}
                {step<5?<button onClick={(e)=>nextStep(e)} className="button is-primary next-button">Next</button>:<button className="button is-primary" onClick={()=>submitForm()}>Submit</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </div>
  );
}

export default License;
