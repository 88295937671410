import React from 'react';

const Step1 = (props) => {

  return (
    <div className={props.step===1?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab1-content">
      <div className="column is-10">
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="field">
              <label className="label">First name</label>
              <div className="control">
                <input className="input" type="text" placeholder="John" id="fname" />
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">Last name</label>
              <div className="control">
                <input className="input" type="text" placeholder="Doe" id="lname" />
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">Email name</label>
              <div className="control">
                <input className="input" type="email" placeholder="yourname@mail.com" id="email" />
              </div>
            </div>
          </div>
          <div className="column is-6 is-flex is-aligned-bottom">
            <p className="is-size-7 has-opacity-65">
              <svg className="is-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>
              </svg>
              We need your email so we can send you the shipping details and updates for the the tag.
            </p>
          </div>

          <div className="column is-12"></div>
          <div className="column is-6">
            <p className="has-text-weight-bold">Address</p>
            <p>This is the address where we will be delivering the pet tag.</p>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">Street address</label>
              <div className="control">
                <input className="input" type="text" placeholder="S Andrews Ave" id="street" />
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">City</label>
              <div className="control">
                <input className="input" type="text" placeholder="Arlington" id="city" />
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">State</label>
              <div className="control">
                <div className="select is-fullwidth is-primary">
                  <select id="state" defaultValue="VA">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">County/City</label>
              <div className="control">
                <div className="select is-fullwidth is-primary">
                  <select id="county" defaultValue="Arlington">
                    <option value="Arlington">Arlington</option>
                    <option value="Richmond">Richmond</option>
                    <option value="Hampton">Hampton</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6"></div>

          <div className="column is-6">
            <div className="field">
              <label className="label">ZIP Code</label>
              <div className="control">
                <input className="input" type="text" placeholder="222 222" id="zip" />
              </div>
            </div>
          </div>
          <div className="column is-6"></div>
        </div>
      </div>
    </div>
  );
}

export default Step1;
