import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";

import './rabies.scss'

import termsCat from 'assets/terms-cat.png';
import termsDog from 'assets/terms-dog.png';



const Rabies = (props) => {

  const [rabies, setRabies] = useState();
  const [licenses, setLicenses] = useState();

  useEffect(() => {
    if (props.county!== null && props.county!== undefined) {
      axios.get('https://roamingtails-api2.herokuapp.com/rabies-certs', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}  `,
        },
        params: {
          petCounty: "Richmond City"
        },
      })
      .then(function (response) {
        setRabies(response.data);
        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    // axios.get('https://roamingtails-api2.herokuapp.com/licenses' )
    // .then(function (response) {
    //   // console.log(response.data);
    //   setLicenses(response.data)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }, [props.county])

  const returnRabies = rabies?rabies.map((item, index) => {
    var results = [];
    let ITEM = item;
    if (licenses) {
      var toSearch = ITEM.petName;
      for(var i=0; i<licenses.length; i++) {
        // console.log(licenses[i])
        // console.log(rabies[i]);
        for(let key in licenses[i]) {
          if(licenses[i][key]===toSearch) {
            results.push(licenses[i]);
          }
        }
      }
      console.log(results)
    }
    return (
      <tr key={index}>
        <td nowrap="true">{item.petName}</td>
        <td nowrap="true">{item.petSpecies}</td>
        <td nowrap="true">{item.petSex}</td>
        <td nowrap="true">{item.petAge}</td>
        <td nowrap="true">{item.petColor}</td>
        <td nowrap="true">{item.petBreed}</td>
        <td nowrap="true">{item.petSpayedNeutered?"Yes":"No"}</td>
        <td nowrap="true">{item.petVaccinationNo}</td>
        <td nowrap="true">{item.petVaccinationExpDate}</td>
        <td nowrap="true">{item.petResidence}</td>
        <td nowrap="true">{item.petCounty}</td>
        <td nowrap="true">{item.petRabiesCertImg}</td>
        <td nowrap="true">{item.ownerName}</td>
      </tr>
    )
  }):"";

  let csvRows = []
  if (rabies) {
    rabies.forEach((item, index) => {
        csvRows.push(item);
      })
  }
  // console.log(csvRows);

  let csvData;
  if (rabies) {
    csvData = csvRows;
    // console.log(csvData)
  }


  return (
    <div>
      {rabies?
        <section className="section">
          <div>
            <div className="card terms-card" style={{marginBottom: "24px"}}>
              <div className="card-header has-text-centered">
                <img src={termsDog} alt="" />
                <div style={{margin: "auto"}}>
                  <p className="has-text-weight-bold is-size-4">Rabies certificate directory</p>
                  <p className="is-size-7">List of all rabies certificate from Richmond City</p>
                </div>
                <img src={termsCat} alt="" />
              </div>
            </div>
          </div>


          <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth" id="table-to-xls">
              <thead>
                <tr>
                  <th nowrap="true" className="has-text-white">Pet name</th>
                  <th nowrap="true" className="has-text-white">Pet species</th>
                  <th nowrap="true" className="has-text-white">Pet sex</th>
                  <th nowrap="true" className="has-text-white">Pet age</th>
                  <th nowrap="true" className="has-text-white">Pet color</th>
                  <th nowrap="true" className="has-text-white">Pet breed</th>
                  <th nowrap="true" className="has-text-white">Pet spayed/neutered</th>
                  <th nowrap="true" className="has-text-white">Pet vaccination no.</th>
                  <th nowrap="true" className="has-text-white">Pet vaccination exp. date</th>
                  <th nowrap="true" className="has-text-white">Pet residence</th>
                  <th nowrap="true" className="has-text-white">Pet county</th>
                  <th nowrap="true" className="has-text-white">Pet rabies cert.</th>
                  <th nowrap="true" className="has-text-white">Pet owner</th>
                </tr>
              </thead>
              <tbody>
                {returnRabies}
              </tbody>
            </table>
          </div>

          {rabies?
            <div className="field">
              {/* <p>Export table as a .csv file</p> */}
              <CSVLink className="button is-primary" data={csvData}>Export .csv</CSVLink>
            </div>:
          ""}
        </section>:
        <section>
          <p>You must have admin rights to view this information</p>
        </section>}

    </div>
  );
}

export default Rabies;
