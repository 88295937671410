import React, { useState } from 'react';
import axios from 'axios';
import Autocomplete from 'react-google-autocomplete';
import { Link } from 'react-router-dom';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import { PDFViewer } from '@react-pdf/renderer';

// import {moment} from 'moment';

import termsCat from 'assets/terms-cat.png';
import termsDog from 'assets/terms-dog.png';



const SendCert = (props) => {
  // const user = props.user;

  const [rabiesImg, setRabiesImg] = useState();
  const [address, setAddress] = useState();
  const [county, setCounty] = useState();
  // const [valid, setValid] = useState(false);
  // const [fileBlob, setFileBlob] = useState();

  const [step, setStep] = useState(1);
  const [difAddress, setDifAddress] = useState()
  const [difAddressFlag, setDifAddressFlag] = useState(false)
  const [difAddressCounty, setDifAddressCounty] = useState()

  const widget = window.cloudinary.createUploadWidget({
    cloudName: 'dnzyoe9rl',
    sources: ['local', 'camera'],
    uploadPreset: 'icmgqhdc'}, (error, result) => {
      if (!error && result && result.event === "success") {
        console.log('Done! Here is the image info: ', result.info);
        setRabiesImg(result.info.secure_url)
      }
    }
  )

  let curr = new Date();
  curr.setDate(curr.getDate() + 3);
  let date = curr.toISOString().substr(0,10);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    section: {
      margin: 4,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 8
    },
    center: {
      textAlign: 'center'
    },
    body: {
      fontSize: "24px"
    }
  });

  const Body = styled.Text`
    font-size: 12px;
  `;

  const BodyBold = styled.Text`
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
  `;

  const Caption = styled.Text`
    font-size: 9px;
    color: #999;
  `;

  const uploadFile = (e) => {
    e.preventDefault();
    widget.open()
  }

  const validate = (e) => {
    if (e.target.value==="") {
      e.target.classList.add('is-danger');
      // setValid(false)
    }
  }

  const resetField = (e) => {
    e.target.classList.remove('is-danger');
  }


  const generateCert = (e) => {
    const target = e.target;
    target.classList.add('is-loading');

    // let inputs = document.querySelectorAll('.validate');

    // inputs.forEach((input, index) => {
    //   if (input.value==="") {
    //     input.classList.add("is-danger");
    //     setValid(false);
    //     return;
    //   } else {
    //     input.classList.remove("is-danger");
    //     setValid(true);
    //   }
    // })

    setTimeout(function() {
      // sendEmail("rsabo@roamingtails.com");
      setStep(2);
      target.classList.remove('is-loading');
    }, 1000)
  }

  const MyDocument = () => (
    <Document externalLinkTarget="_blank">
      <Page size="A4">
        <View style={styles.section, {marginTop: "40px"}}>
          <Text style={styles.center}>RABIES VACCINATION CERTIFICATE</Text>
          <Caption style={styles.center}>02 March 2020 / Richmond City</Caption>
        </View>
        <BodyBold style={{paddingLeft: "24px", marginTop: "48px"}}>Owner information</BodyBold>
        <View style={styles.page}>
          <View style={styles.section}>
            <Body>{document.getElementById('ownerName').value}</Body>
            <Caption>Owner's Name</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('ownerAddress').value}</Body>
            <Caption>Owner's Address</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('ownerPhone').value}</Body>
            <Caption>Telephone</Caption>
          </View>
        </View>
        <BodyBold style={{paddingLeft: "24px", marginTop: "48px"}}>Pet information</BodyBold>
        <View style={styles.page}>
          <View style={styles.section}>
            <Body>{document.getElementById('petName').value}</Body>
            <Caption>Pet name</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petSpecies').value}</Body>
            <Caption>Pet species</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petBreed').value}</Body>
            <Caption>Pet breed</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petColor').value}</Body>
            <Caption>Pet color</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petSex').value}</Body>
            <Caption>Pet sex</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petAge').value}</Body>
            <Caption>Pet age (years)</Caption>
          </View>
          <View style={styles.section}>
            <Body>Yes</Body>
            <Caption>Pet spayed/neutered</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('ownerAddress').value}</Body>
            <Caption>Pet residence</Caption>
          </View>
          <View style={styles.section}>
            <Body>{county}</Body>
            <Caption>Pet county</Caption>
          </View>
        </View>

        <BodyBold style={{paddingLeft: "24px", marginTop: "48px"}}>Vaccination information</BodyBold>
        <View style={styles.page}>
          <View style={styles.section}>
            <Body>{document.getElementById('petVaccinationNo').value}</Body>
            <Caption>Pet vaccination number</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petVaccinationExpDate').value}</Body>
            <Caption>Pet vaccination date</Caption>
          </View>
          <View style={styles.section}>
            <Body>1 year</Body>
            <Caption>Pet vaccination period</Caption>
          </View>
          <View style={styles.section}>
            <Body>{document.getElementById('petVaccinationExpDate').value}</Body>
            <Caption>Pet vaccination expiration date</Caption>
          </View>
        </View>

        <BodyBold style={{paddingLeft: "24px", marginTop: "48px"}}>Veterinarian info</BodyBold>
        <View style={styles.page}>
          <View style={styles.section}>
            <Body>Radu Sabo</Body>
            <Caption>Veterinarian name</Caption>
          </View>
          <View style={styles.section}>
            <Body>2039040</Body>
            <Caption>Veterinarian license number</Caption>
          </View>
          <View style={styles.section}>
            <Body>Radu Sabo</Body>
            <Caption>Veterinarian signature</Caption>
          </View>
        </View>

      </Page>
    </Document>
  );

  const sendData = (e) => {
    e.preventDefault();
    const target = e.target;
    target.classList.add('is-loading');

    let rabiesData = {
      ownerName: document.getElementById('ownerName').value,
      ownerAddress: address?address.formatted_address:"",
      petSpecies: document.getElementById('petSpecies').value,
      petSex: document.getElementById('petSex').value,
      petAge: document.getElementById('petAge').value,
      petColor: document.getElementById('petColor').value,
      petBreed: document.getElementById('petBreed').value,
      petSpayedNeutered: document.getElementById('petSpayedNeutered').checked,
      petVaccinationNo: document.getElementById('petVaccinationNo').value,
      petVaccinationExpDate: document.getElementById('petVaccinationExpDate').value,
      petResidence: address?address.formatted_address:"",
      petName: document.getElementById('petName').value,
      petCounty: county?county:"",
      rabiesCertImg: rabiesImg?rabiesImg:""
    }

    let petData = {
      petName: document.getElementById('petName').value,
      petSpecies: document.getElementById('petSpecies').value,
      petSex: document.getElementById('petSex').value,
      petAge: document.getElementById('petAge').value,
      petColor: document.getElementById('petColor').value,
      petBreed: document.getElementById('petBreed').value,
      petSpayedNeutered: document.getElementById('petSpayedNeutered').checked,
      petVaccinationNo: document.getElementById('petVaccinationNo').value,
      petVaccinationExpDate: document.getElementById('petVaccinationExpDate').value,
      petResidence: address?address.formatted_address:"",
      petCounty: county?county:"",
      rabiesCertImg: rabiesImg?rabiesImg:"",
      petLat: address?address.geometry.location.lat():"",
      petLng: address?address.geometry.location.lng():""
    }

    let ownerData = {
      ownerName: document.getElementById('ownerName').value,
      ownerAddress: document.getElementById('ownerAddress').value,
      ownerEmail: document.getElementById('ownerEmail').value,
      ownerPhone: document.getElementById('ownerPhone').value
    }
    axios.post('https://roamingtails-api2.herokuapp.com/rabies-certs', rabiesData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}  `,
      },
    })
    .then(function(response) {
      // Rabies sent successfuly

      // Create owner
      sendEmail("mail@roamingtails.com");
      axios.post('https://roamingtails-api2.herokuapp.com/owners', ownerData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}  `,
        },
      })
      .then(function(response) {
        console.log(response);
      })
      .catch(function(error) {
        // console.log(error);
      })

      // Create pet
      axios.post('https://roamingtails-api2.herokuapp.com/pets', petData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}  `,
        },
      })
      .then(function(response) {
        console.log(response);
        sendEmail(document.getElementById('ownerEmail').value);
        target.classList.remove('is-loading');
        setStep(3);
      })
      .catch(function(error) {
        console.log(error);
      })

      // Log rabies
      console.log(response);
    })
    .catch(function(error) {
      // console.log(error);
    })
  }

  const sendEmail = (email, content) => {
    let mailContent =
    '<div class="content">' +
      '<p>Owner name: '+ document.getElementById("ownerName").value +'</p>' +
      '<p>Email: '+ document.getElementById("ownerEmail").value +'</p>' +
      '<p>Address: '+ document.getElementById("ownerAddress").value +'</p>' +
      '<p>County/City: '+ (county?county:"") +'</p>' +
      '<p>Pet name: '+ document.getElementById("petName").value +'</p>' +
      '<p>Pet species: '+ document.getElementById("petSpecies").value +'</p>' +
      '<p>Pet sex: '+ document.getElementById("petSex").value +'</p>' +
      '<p>Pet age: '+ document.getElementById("petAge").value +'</p>' +
      '<p>Pet color: '+ document.getElementById("petColor").value +'</p>' +
      '<p>Pet breed: '+ document.getElementById("petBreed").value +'</p>' +
      '<p>Pet residence: '+ document.getElementById('ownerAddress').value +'</p>' +
    '</div>';

    console.log(mailContent)

    axios.post('https://roamingtails-api2.herokuapp.com/email', {
      to: email,
      from: 'mail@roamingtails.com',
      replyTo: 'mail@roamingtails.com',
      subject: "New rabies certtification sent!",
      text: mailContent,
      html: mailContent
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}  `,
      },
    })
    .then(function (res) {
      console.log(res);
    })
    .catch(function (error) {
      console.log(error);
  });
  }




  return (
    <section className="section">
      <form className={step===1?"":"is-hidden"} autoComplete="on">
        <div className="card terms-card" style={{marginBottom: "24px"}}>
          <div className="card-header has-text-centered">
            <img src={termsDog} alt="" />
            <p className="has-text-weight-bold is-size-4">New rabies certificate</p>
            <img src={termsCat} alt="" />
          </div>
        </div>
        <div className="columns is-multiline is-aligned-bottom">
          <div className="column is-6">
            <div className="field">
              <div className="file">
                <label className="file-label" style={{width: "100%"}}>
                  <input className="file-input" type="file" onClick={(e) => uploadFile(e)}/>
                  <span className="file-cta" style={{width: "100%"}}>
                    <span className="file-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12.71 11.29a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-2 2a1 1 0 0 0 1.42 1.42l.29-.3V17a1 1 0 0 0 2 0v-2.59l.29.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9v-.06zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3z"/>
                      </svg>
                    </span>
                    <span className="file-label">
                      Choose a file…
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <p className="is-size-7 has-opacity-65">
              <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
              </svg>
              If you have an existing rabies certificate that you want to send, you can either scan and upload it or take a picture of it</p>
          </div>

        {/* <hr></hr> */}

        <div className="column is-12">
          <div className="field">
            <p className="has-text-weight-bold">Owner information</p>
          </div>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Owner name</label>
            <div className="control">
              <input type="text" id="ownerName" placeholder="Michael Scott" className="input validate" onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Owner address</label>
            <div className="control">
              <Autocomplete
                className="input validate"
                onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}
                placeholder="22 Arthur Street, Hollywood, FL"
                id="ownerAddress"
                onPlaceSelected={(place) => {
                  setAddress(place)
                  axios.get('https://geo.fcc.gov/api/census/area?lat='+place.geometry.location.lat()+'&lon='+place.geometry.location.lng()+'&format=json')
                    .then(function (response) {
                      // console.log(response.data.results[0].county_name);
                      setCounty(response.data.results[0].county_name);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }}
                types={['address']}
                componentRestrictions={{country: "us"}}
              />
              {/* <input type="text" id="ownerAddress" placeholder="22 Arthur Street, Hollywood, FL" className="input validate"></input> */}
            </div>
            <div className="field">
              {county?<p>Based on your address, your pet belongs to <span className="has-text-weight-bold">{county}</span> administration</p>:""}
            </div>
          </div>
        </div>
        <div className="column is-6">
          <p className="is-size-7 has-opacity-65">
            <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
            </svg>
            If the pet has a different residence address, you will be able to input it below</p>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Owner email</label>
            <div className="control">
              <input type="email" id="ownerEmail" placeholder="owner@email.com" className="input validate" onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <p className="is-size-7 has-opacity-65">
            <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
            </svg>
            This is where the owner will receive a digital copy of the rabies certificate</p>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Owner phone number</label>
            <div className="control">
              <input type="text" id="ownerPhone" placeholder="(754) 320 9978" className="input validate" onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>


        {/* Pet information */}
        <div className="column is-12">
          <div className="field">
            <p className="has-text-weight-bold">Pet information</p>
          </div>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet name</label>
            <div className="control">
              <input type="text" id="petName" placeholder="Skippy" className="input validate" onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet species</label>
            <div className="control">
              <input type="text" id="petSpecies" placeholder="Dog" className="input validate" onBlur={(e)=>validate(e)} onFocus={(e)=>resetField(e)}></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet sex</label>
            <div className="control">
              <input type="text" id="petSex" placeholder="Female" className="input validate"></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <label className="label">Pet age</label>
          <div className="field has-addons">
            <p className="control" style={{width: "100%"}}>
              <input type="text" id="petAge" placeholder="1" className="input validate"></input>
            </p>
            <p className="control">
              <span className="button is-static">
                year(s)
              </span>
            </p>
          </div>
        </div>
        <div className="column is-6">
          <p className="is-size-7 has-opacity-65">
            <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
            </svg>
            Use decimal notation to indicate months (eg. 1.8 years)</p>
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet color</label>
            <div className="control">
              <input type="text" id="petColor" placeholder="White" className="input validate"></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet breed</label>
            <div className="control">
              <input type="text" id="petBreed" placeholder="French bulldog" className="input validate"></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet status</label>
            <div className="control">
              <label className="checkbox">
              <input type="checkbox" id="petSpayedNeutered" placeholder="Spayed/Neutered" className="checkbox" />
                Spayed/Neutered
              </label>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet vaccination number</label>
            <div className="control">
              <input type="text" id="petVaccinationNo" placeholder="203920" className="input validate"></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet vaccination expiration date</label>
            <div className="control">
              <input type="date" id="petVaccinationExpDate" defaultValue={date} className="input validate" ></input>
            </div>
          </div>
        </div>
        <div className="column is-6">
          {/* <p className="is-size-7 has-opacity-65">right side info</p> */}
        </div>

        <div className="column is-6">
          <div className="field">
            <label className="label">Pet residence</label>
          </div>
          <div className="field">
            <div className="control">
              <label className="checkbox">
              <input type="checkbox" className="checkbox" onChange={() => setDifAddressFlag(!difAddressFlag)}/>
                Different address than owner
              </label>
            </div>
          </div>
          <div className="field">
            {difAddressFlag?<div className="control">
              <Autocomplete
                className="input validate"
                placeholder="22 Arthur Street, Hollywood, FL"
                onPlaceSelected={(place) => {
                  console.log(place);
                  setDifAddress(place)
                  axios.get('https://geo.fcc.gov/api/census/area?lat='+place.geometry.location.lat()+'&lon='+place.geometry.location.lng()+'&format=json')
                    .then(function (response) {
                      console.log(response.data.results[0].county_name);
                      setDifAddressCounty(response.data.results[0].county_name);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }}
                types={['address']}
                componentRestrictions={{country: "us"}}
              />
            </div>:""}
          </div>
          {county?<div className="field">
            {difAddressCounty?
              <p>Based on your pets address, it belongs to <span className="has-text-weight-bold">{difAddressCounty}</span> administration</p>:
              <p>Based on your address, your pet belongs to <span className="has-text-weight-bold">{county}</span> administration</p>}
            </div>:""}
        </div>
        <div className="column is-6">
          <p className="is-size-7 has-opacity-65">
            <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
            </svg>
            Check if pet has a different residence address than owner, then input pet's residence address</p>
        </div>
        </div>
      </form>

      {step===2?<div>
          <div className="columns is-centered">
  							<div className="column is-12">
                  <div className="card terms-card" style={{marginBottom: "24px"}}>
                    <div className="card-header has-text-centered">
                      <img src={termsDog} alt="" />
                      <p className="has-text-weight-bold is-size-4">Certificate generated!</p>
                      <img src={termsCat} alt="" />
                    </div>
                    <div className="card-content">
                      <PDFViewer style={{width: "100%", height: "400px"}}>
                        <MyDocument />
                      </PDFViewer>
                    </div>
                  </div>
  								{/* <label className="checkbox" style={{margin: "24px 0"}}>
  								  <input type="checkbox" id="terms" />
  								  Owner agrees to send this certificate to the treasurer
  								</label> */}
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <p>As this pet belongs to the <span className="has-text-weight-bold">{county?county:"county"}</span> administration, by hitting send the certificate will be automatically sent to <span className="has-text-weight-bold">treasurer@{county?county.toLowerCase():"county"}.com</span></p>
                    </div>
                    <div className="column is-6"></div>

                    <div className="column is-6">
                      <div className="field">
                        If you want to manually enter the address where you want to send the rabies certificate, use the input below.
                      </div>
                      <div className="field">
                        <label className="label">Owner name</label>
                        <div className="control">
                          <input type="text" id="newAddress" placeholder="newaddress@mail.com" className="input"></input>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <p className="is-size-7 has-opacity-65">
                        <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 11a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zm.38-3.92a1 1 0 0 0-.76 0 1 1 0 0 0-.33.21 1.15 1.15 0 0 0-.21.33A.84.84 0 0 0 11 8a1 1 0 0 0 .29.71 1.15 1.15 0 0 0 .33.21A1 1 0 0 0 13 8a1.05 1.05 0 0 0-.29-.71 1 1 0 0 0-.33-.21zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                        </svg>
                        Filling in this field will overwrite the email address we send the rabies certificate</p>
                    </div>
                  </div>
                  <div className="field">

                  </div>

  								{/* <div className="has-text-danger terms-error" style="display: none;" >You have to agree to our Terms and Conditions</div> */}
  							</div>
        </div>
      </div>:""}

      {step===3?<div>
        <div className="columns">
          <div className="column is-12 content">
            <div className="card terms-card" style={{marginBottom: "24px"}}>
              <div className="card-header has-text-centered">
                <img src={termsDog} alt="" />
                <p className="has-text-weight-bold is-size-4">Certificate sent!</p>
                <img src={termsCat} alt="" />
              </div>
              {/* <div className="card-content">
                <p>{}</p>
              </div> */}
            </div>
            <p>
              <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
              </svg>
              You will receive a confirmation email that the certificate was successfuly sent.</p>
            <p>
              <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
              </svg>
              Both the owner and the treasurer will receive a digital copy of the certificate with all the details.</p>
            <p>
              <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
              </svg>
              The certificate got registered in the database and is accessible in the <Link to="/dashboard/rabies">rabies certificates directory</Link></p>
          </div>
        </div>
      </div>:""}
      <div className="columns">
        <div className="column is-12">
          <div className="buttons">
            {step===1?<button className="button is-primary" onClick={(e) => generateCert(e)}>Generate rabies certificate</button>:""}
            {step===1?<button className="button is-white" onClick={(e) => sendData(e)}>Send data directly</button>:""}
            {step===2?<button className="button" onClick={() => setStep(1)}>Back</button>:""}
            {step===2?<button className="button is-primary" onClick={(e) => sendData(e)}>Send data</button>:""}
          </div>
        </div>
      </div>

    </section>
  );
}

export default SendCert;
