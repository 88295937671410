import React from 'react';
import { Link } from 'react-router-dom';

const Vets = () => {

  return (
    <div>
      <div><Link to="/vets/offices">Vet offices</Link></div>
      <div><Link to="/vets/send">Send certificate</Link></div>
    </div>
  );
}

export default Vets;
