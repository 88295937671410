import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {

  return (
    <div>
      <section className="section">
        <div><Link to="/dashboard/rabies">Rabies directory</Link></div>
        <div><Link to="/dashboard/licenses">Licenses directory</Link></div>
      </section>
    </div>
  );
}

export default Dashboard;
