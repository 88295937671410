import React, {useEffect, useState} from 'react';
import axios from 'axios';

import {AuthConsumer} from '../utils/AuthContext'

const VetOffices = () => {

  const [offices, setOffices] = useState();

  useEffect(() => {
    axios.get('https://roamingtails-api2.herokuapp.com/vet-offices', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}  `,
      },
    })
    .then(function (response) {
      setOffices(response.data);
      console.log(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

  return (
    <section className="section">
        <AuthConsumer>
          {({ isAuth, login, logout, user }) => (
            <div>
              {offices?offices.map((office, index) => (
              <div className="card" key={index}>
                <div className="card-content">
                  <div >
                    <p>{office.officeName}</p>
                    <p>{office.officeAddress}</p>
                    <p>{office.officeWebsite}</p>
                  </div>
                </div>
              </div>)):<p>You have to be registered as a vet to access the vet directory.</p>}
            </div>
          )}
        </AuthConsumer>
    </section>
  );
}

export default VetOffices;
