import React from 'react';
import LicensesMap from './map.js';

const Map = (props) => {

  return (
    <div>
      <LicensesMap county={props.county}/>
    </div>
  );
}

export default Map;
